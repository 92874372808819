import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import myMenu from './reducers/myMenu';
import customProduct from './reducers/customProduct';
import restaurantInitialSteps from './reducers/restaurantInitialSteps';
import thunk from 'redux-thunk';

const reducer = combineReducers({
  myMenu,
  customProduct,
  restaurantInitialSteps,
});

const store = createStore(
  reducer,
  {},
  compose(
    applyMiddleware(thunk)
    /** Only for debugging mode: **/
    //,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;