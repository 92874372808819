import React from 'react';
import { connect } from 'react-redux';
import DropDownValidated from '../../../controls/DropdownValidated';
import ImageUploader from 'react-images-upload';
import { List, ListItem } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ValidatedTextField } from '../../../controls/ValidatedTextField';

const accountTypes = [
  "Ahorros",
  "Corriente"
];

const banks = [
  "BANCAMIA S.A.",
  "BANCO AGRARIO",
  "BANCO AV VILLAS",
  "BANCO BBVA COLOMBIA S.A.",
  "BANCO CAJA SOCIAL",
  "BANCO COOPERATIVO COOPCENTRAL",
  "BANCO DE BOGOTA",
  "BANCO DE OCCIDENTE",
  "BANCO FALABELLA ",
  "BANCO GNB SUDAMERIS",
  "BANCO ITAU",
  "BANCO PICHINCHA S.A.",
  "BANCO POPULAR",
  "BANCO SANTANDER COLOMBIA",
  "BANCO SERFINANZA",
  "BANCOLOMBIA",
  "BANCOOMEVA S.A.",
  "CFA COOPERATIVA FINANCIERA",
  "CITIBANK ",
  "CONFIAR COOPERATIVA FINANCIERA",
//  "MOVII S.A.",
  "NEQUI",
  "DAVIPLATA",
//  "RAPPIPAY",
  "SCOTIABANK COLPATRIA"
];

const banksWithoutAccountType = [
  "NEQUI",
  "DAVIPLATA",
//  "RAPPIPAY"
];

const PASSPORT_ID = "PP Pasaporte";

const documentTypes = [
  PASSPORT_ID,
  "RC Registro Civil de Nacimiento",
  "TI Tarjeta de Identidad",
  "CE Cédula de Extranjería",
  "CC Cédula de Ciudadanía",
  "NUIP Número Único de Identificación",
  "NIT Nit Empresa"
];

class PaymentMethodForm extends React.Component {
  constructor(props) {
    super(props);
    let paymentInfo = this.props.restaurantInitialSteps.paymentInfo || {};
    this.state = {

      bankName: paymentInfo.bankName || null,
      accountType: paymentInfo.accountType || accountTypes[0],
      ownerName: paymentInfo.ownerName || null,
      documentType: paymentInfo.documentType || null,
      ownerIdentification: paymentInfo.ownerIdentification || null,
      accountNumber: paymentInfo.accountNumber || null,

      bankNameValid: false,
      
      documentTypeValid: false,

      ownerNameValid: false,
      ownerIdentificationValid: false,
      accountNumberValid: false,

      isValidForm: false,

      photos: [],
      photoUrls: [],
    };
    this.validate();
  }
  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      photos: this.state.photos.concat(pictureFiles),
      photoUrls: this.state.photoUrls.concat(pictureDataURLs)
    }, this.validate);
  }
  validate() {
    let isValid = false;
    let paymentMethod = {};
    if (this.state.bankNameValid) {
      paymentMethod["bankName"] = this.state.bankName;
      paymentMethod["ownerName"] = this.state.ownerName;
      paymentMethod["documentType"] = this.state.documentType;
      paymentMethod["ownerIdentification"] = this.state.ownerIdentification;
      if(this.state.photos.length > 0)
        paymentMethod["QrCodeImage"] = this.state.photos[0];

      const generalFieldsValid = (
        this.state.ownerNameValid &&
        this.state.documentTypeValid &&
        this.state.ownerIdentificationValid
      );
      const bankValid  = (
        banksWithoutAccountType.indexOf(this.state.bankName) === -1 &&
        this.state.accountNumberValid
      );
      if(bankValid) {
        paymentMethod["accountType"] = this.state.accountType;
        paymentMethod["accountNumber"] = this.state.accountNumber;
      }
      const appsValid = (
        banksWithoutAccountType.indexOf(this.state.bankName) !== -1 &&
        this.state.phoneNumberValid
      );
      if(appsValid) {
        paymentMethod["phoneNumber"] = this.state.phoneNumber;
      }
      isValid = generalFieldsValid && (bankValid || appsValid);
    }
    const QrCodeImage = this.state.photos.length ? this.state.photos[0] : null;
    this.setState({isValidForm: isValid});
    if(isValid) {
      this.props.onCompletePaymentMethod(paymentMethod, QrCodeImage);
    }
    this.props.onChangeIsValid(isValid);
  }
  render() {
    return (
      <div className="container-form">
        <List>
          <ListItem>
            <DropDownValidated
              dataSource = {banks}
              defaultValue = {this.state.bankName}
              errorMessage = "Debes seleccionar un banco"
              id = "bank-select"
              label = "Banco"
              onChange = {(bankNameSelected) => {
                this.setState({
                  bankName: bankNameSelected
                });
              }}
              onValidate = {(isValid) => {
                this.setState({ bankNameValid: isValid}, () => {
                  this.validate();
                });
            }} />
          </ListItem>
          {this.state.bankNameValid &&
            <>
              {banksWithoutAccountType.indexOf(this.state.bankName) === -1 &&
                <>
                  <ListItem>
                    <ToggleButtonGroup
                      value={this.state.accountType}
                      exclusive
                      onChange={(event, newAccountType) => {
                        if (newAccountType !== null) {
                          this.setState({ accountType: newAccountType });
                        }
                      }}
                      aria-label="text alignment"
                    >
                      <ToggleButton value={accountTypes[0]} aria-label="left aligned">
                        {accountTypes[0]}
                      </ToggleButton>
                      <ToggleButton value={accountTypes[1]} aria-label="left aligned">
                        {accountTypes[1]}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ListItem>
                  <ListItem>
                    <ValidatedTextField label="Número de cuenta" defaultValue={this.state.accountNumber} validations={[
                      {type: 'isRequired', msg: 'El número de cuenta es requerido'},
                      {type: 'accountNumber', msg: 'Ingrese una número de cuenta válido'}
                    ]}
                    onChange={(newValue) => { this.setState({ accountNumber: newValue }); }}
                    onValidate={(isValid) => {
                      this.setState({ accountNumberValid: isValid}, () => {
                        this.validate();
                      });
                    }}></ValidatedTextField>
                  </ListItem>
                </>
              }
              {banksWithoutAccountType.indexOf(this.state.bankName) !== -1 &&
                <ListItem>
                  <ValidatedTextField label="Número de celular" defaultValue={this.state.phoneNumber} validations={[
                    {type: 'isRequired', msg: 'El número de celular es requerido'},
                    {type: 'phone', msg: 'Ingrese una número de cuenta válido'}
                  ]}
                  onChange={(newValue) => { this.setState({ phoneNumber: newValue }); }}
                  onValidate={(isValid) => {
                    this.setState({ phoneNumberValid: isValid}, () => {
                      this.validate();
                    });
                  }}></ValidatedTextField>
                </ListItem>
              }
              <ListItem>
                <ValidatedTextField label="Nombre del titular" defaultValue={this.state.ownerName} validations={[
                  {type: 'isRequired', msg: 'El nombre es requerido'}
                ]}
                onChange={(newValue) => { this.setState({ ownerName: newValue }); }}
                onValidate={(isValid) => {
                  this.setState({ ownerNameValid: isValid}, () => {
                    this.validate();
                  });
                }}></ValidatedTextField>
              </ListItem>
              <ListItem>
                <DropDownValidated
                  dataSource={documentTypes}
                  defaultValue={this.state.documentType}
                  errorMessage = "Debes seleccionar un tipo de documento"
                  id = "documentType-select"
                  label = "Tipo de documento"
                  onChange = {(documentTypeSelected) => {
                    this.setState({
                      documentType: documentTypeSelected
                    });
                  }}
                  onValidate = {(isValid) => {
                    this.setState({ documentTypeValid: isValid}, () => {
                      this.validate();
                    });
                }} />
              </ListItem>
              <ListItem>
                <ValidatedTextField label="Identificación del titular" defaultValue={this.state.ownerIdentification} validations={[
                  {type: 'isRequired', msg: 'La identificación es requerida'},
                  this.state.documentType === PASSPORT_ID ? {type: 'passport', msg: 'Ingrese una identificación válida'} : {type: 'identification', msg: 'Ingrese una identificación válida'} 
                ]}
                onChange={(newValue) => { this.setState({ ownerIdentification: newValue }); }}
                onValidate={(isValid) => {
                  this.setState({ ownerIdentificationValid: isValid}, () => {
                    this.validate();
                  });
                }}></ValidatedTextField>
              </ListItem>
              <ListItem>
                <span>Código QR para pagos:</span>
              </ListItem>
              <ListItem>
                <ImageUploader
                  withIcon={true}
                  buttonText='Selecciona una imagen'
                  onChange={this.onDrop.bind(this)}
                  imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
                  maxFileSize={5242880}
                  withPreview={false}
                  accept="image"
                  withLabel={false}
                  fileSizeError='imagen muy grande (Máximo de 5 MB)'
                  fileTypeError='debes subir solo imágenes en formato .jpg, jpeg, .gif, .png, .gif'
                />
              </ListItem>
            </>
          }
        </List>
    </div>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PaymentMethodForm);
