import React from 'react';
import { List, ListItem, Button } from '@material-ui/core';
import IngredientCategory from './categoryOfIngredients/IngredientCategory';
import CategoryIngredientDialog from './categoryOfIngredients/CategoryIngredientDialog';
import { connect } from 'react-redux';

class CustomProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
    }
  }
  render() {
    const { customProduct } = this.props;
    return (
      <div className="content">
        <List>
          <ListItem className="container-space-between"> <h3>{customProduct.name}</h3> </ListItem>
        </List>

        <List>
          {customProduct.categories.map((cat, index) => {
            return (
              <ListItem key={ index }>
                <IngredientCategory category={ cat } />
              </ListItem>
            );
          })}
        </List>

        <List>
          <ListItem className="container-center">
            <Button color="primary" variant="contained" size="small" onClick={() => { this.setState({ openDialog: true }); }}> Agregar Categoría </Button>
            <CategoryIngredientDialog open={this.state.openDialog} modeEdition={false} onCloseDialog = { () => { this.setState({ openDialog: false }); } } />
          </ListItem>
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customProduct: state.customProduct,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomProduct);