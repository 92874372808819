import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { ValidatedTextField } from '../../../controls/ValidatedTextField';
import setRestaurantBasicInfo from '../../../redux/actions/restaurantInitialSteps/setRestaurantBasicInfo';
import { connect } from 'react-redux';
import { generateAlias } from '../../../util/utils';
import FileUploadZoom from '../../../controls/FileUploadZoom';

class RestaurantBasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.restaurantInitialSteps.basicInfo ? this.props.restaurantInitialSteps.basicInfo.name : '',
      contactName: this.props.restaurantInitialSteps.basicInfo ? this.props.restaurantInitialSteps.basicInfo.contactName : '',
      whatsappNumber: this.props.restaurantInitialSteps.basicInfo ? this.props.restaurantInitialSteps.basicInfo.whatsappNumber : '',
      alias: this.props.restaurantInitialSteps.basicInfo ? this.props.restaurantInitialSteps.basicInfo.alias : '',
      photoURL: this.props.restaurantInitialSteps.basicInfo ? this.props.restaurantInitialSteps.basicInfo.photoURL: '',

      nameValid: false,
      whatsappNumberValid: false,
      aliasValid: false,
      
      photos: [],
    };
  }
  onSave(callback) {
    this.props.setRestaurantBasicInfo({
      name: this.state.name,
      contactName: this.state.contactName,
      whatsappNumber: this.state.whatsappNumber,
      photo: this.state.photos.length > 0 ? this.state.photos[0] : null,
      UID: sessionStorage.getItem('UID')
    }, callback);
  }
  checkIsValid() {
    if(this.props.onChange){
      const photo = this.state.photos.length > 0 ? this.state.photos[0] : null;
      this.props.onChange(this.state.name, this.state.contactName, this.state.whatsappNumber, photo);
    }
    this.props.onChangeIsValid(this.state.nameValid && this.state.contactNameValid && this.state.whatsappNumberValid);
  }
  /**
  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      photos: this.state.photos.concat(pictureFiles)
    }, this.checkIsValid);
  }
   */
  render() {
    return (
      <div className="content">
        <h3>Paso 1: Información de tu establecimiento</h3>
        <List>
          <ListItem>
            <ValidatedTextField label="nombre de tu establecimiento" defaultValue={this.state.name} validations={[
              {type: 'isRequired', msg: 'El nombre es requerido'}
            ]} onChange={(newValue) => {
              const newAlias = generateAlias(newValue);
              this.setState({ name: newValue, alias: newAlias });
            }} onValidate={(isValid) => {
              this.setState({ nameValid: isValid}, () => { this.checkIsValid(); });
            }}></ValidatedTextField>
          </ListItem>
          <ListItem>
            <ValidatedTextField label="nombre de contacto" defaultValue={this.state.contactName} validations={[
              {type: 'isRequired', msg: 'El nombre de contacto es requerido'}
            ]} onChange={(newValue) => {
              this.setState({ contactName: newValue });
            }} onValidate={(isValid) => {
              this.setState({ contactNameValid: isValid}, () => { this.checkIsValid(); });
            }}></ValidatedTextField>
          </ListItem>
          <ListItem>
            <ValidatedTextField label="número de whatsapp" defaultValue={this.state.whatsappNumber} validations={[
              {type: 'isRequired', msg: 'El número de whatsapp es requerido'},
              {type: 'phone', msg: 'Ingresa un celular válido'},
            ]} onChange={(newValue) => { this.setState({ whatsappNumber: newValue }); }} onValidate={(isValid) => { this.setState({ whatsappNumberValid: isValid}, () => { this.checkIsValid() }); }}></ValidatedTextField>
          </ListItem>
          <ListItem>
            {/*
            <ImageUploader
                  withIcon={true}
                  buttonText='Selecciona una imagen'
                  onChange={this.onDrop.bind(this)}
                  imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
                  maxFileSize={5242880}
                  withPreview
                  singleImage
                  accept="image"
                  withLabel={false}
                  fileSizeError='imagen muy grande (Máximo de 5 MB)'
                  fileTypeError='debes subir solo imágenes en formato .jpg, jpeg, .gif, .png, .gif'
              />
              */}
              {/*
              <FileUpload sourcePhotoURL={this.state.photoURL} onSelectedImage={(blob) => {
                this.setState({
                  photos: [blob]
                }, this.checkIsValid);
              }}/>
              */}
            <FileUploadZoom sourcePhotoURL={this.state.photoURL} onSelectedImage={(blob) => {
                this.setState({
                  photos: [blob]
                }, this.checkIsValid);
              }}/>
          </ListItem>
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  restaurantInitialSteps: state.restaurantInitialSteps,
});

const mapDispatchToProps = {
  setRestaurantBasicInfo,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RestaurantBasicInfo);