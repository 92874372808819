import React from 'react';
import { AppBar } from '@material-ui/core';
import { Link, Toolbar, IconButton } from '@material-ui/core';
import logoAlacuenta from '../img/Alacuenta-logo.png';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import MenuItem from '@material-ui/core/MenuItem';
import { Routes } from '../routes';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import getRestaurant from '../redux/actions/restaurantInitialSteps/getRestaurant';
import { SERVICE_PREFIX } from '../config-front';

class AppToolBar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      mainMenuEl: null,
      userMenuEl: null
    }
    this.props.getRestaurant(sessionStorage.getItem('UID'), () => {});
  }
  /** Main menu */
  handleMainMenuClick = (event) => {
    this.setState({
      mainMenuEl: event.currentTarget
    });
  }
  handleMainMenuClose = () => {
    this.setState({
      mainMenuEl: null
    });
  }
  goToEditMyAccount = () => {
    this.handleMainMenuClose();
    window.location.hash = Routes.getEditMyAccount();
  }
  goToEditMenu = () => {
    this.handleMainMenuClose();
    window.location.hash = Routes.getEditMenu();
  }
  goToFAQ = () => {
    this.handleMainMenuClose();
    window.location.hash = Routes.getFAQ();
  }
  goToContactPage = () => {
    this.handleMainMenuClose();
    window.location.hash = Routes.getContactPage();
  }
  goToMyPage = () => {
    this.handleMainMenuClose();
    window.location.hash = Routes.getDashboard();
  }

  /** User menu */
  handleUserMenuClick = (event) => {
    this.setState({
      userMenuEl: event.currentTarget
    });
  }
  handleUserMenuClose = () => {
    this.setState({
      userMenuEl: null
    });
  }
  render = () => {
    const { signOut, user } = this.props;
    return (
      <AppBar position="sticky" className="app-bar" style={SERVICE_PREFIX.includes("http://localhost") ? { backgroundColor:"#0f0"} : {}}>
        <Toolbar>
          {this.props.restaurantInitialSteps && this.props.restaurantInitialSteps.setupComplete === true &&
            <>
              <IconButton edge="start" className="menuButton" color="inherit" aria-label="menu" onClick={this.handleMainMenuClick}>
                <MenuIcon />
              </IconButton>
          
              <Menu
                id="simple-menu"
                anchorEl={this.state.mainMenuEl}
                keepMounted
                open={Boolean(this.state.mainMenuEl)}
                onClose={this.handleMainMenuClose}
              >
                <MenuItem onClick={this.goToEditMyAccount}>Mi cuenta</MenuItem>
                <MenuItem onClick={this.goToEditMenu}>Mis productos</MenuItem>
                <MenuItem onClick={this.goToFAQ}>FAQ</MenuItem>
                <MenuItem onClick={this.goToMyPage}>Mi Página</MenuItem>
                <MenuItem onClick={this.goToContactPage}>Contáctanos</MenuItem>
              </Menu>
            </>
          }

          <Typography className="title" component="div">
            <Link color="inherit" href={Routes.getDashboard()} className="logo-alacuenta">
              <img src={logoAlacuenta} alt="logo-alacuenta" width={50} height={50} />
            </Link>
            <div className="title-container">
              <h1 className="title-app"> alacuenta </h1>
              <span className="title-app subtitle"> .com </span>
            </div>
          </Typography>

          <IconButton edge="start" className="menuButton" color="inherit" aria-label="menu" onClick={this.handleUserMenuClick}>
            <PersonIcon />
          </IconButton>
        </Toolbar>

        {this.props.restaurantInitialSteps.setupComplete === true &&
          <Menu
              id="simple-menu"
              anchorEl={this.state.userMenuEl}
              keepMounted
              open={Boolean(this.state.userMenuEl)}
              onClose={this.handleUserMenuClose}
          >
            <MenuItem><PersonIcon />{user.displayName}</MenuItem>
            <MenuItem onClick={ signOut }>Cerrar Sesión</MenuItem>
          </Menu>
        }
      </AppBar>
    );
  }
}

const mapStateToProps = state => ({
  restaurantInitialSteps: state.restaurantInitialSteps,
});

const mapDispatchToProps = {
  getRestaurant,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppToolBar));
