import { SERVICE_PREFIX } from '../config-front';

const service_prefix = SERVICE_PREFIX;
const PREFIX_ROLE = 'restaurant';

export default class MyMenuDAO {
/****************************************************************************************/
/******** Menu ********/
/****************************************************************************************/
  static createMenu(UID, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/menu`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ UID: UID }),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
  static changeVisibleMenu(payload, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/menu`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
/****************************************************************************************/
/******** Category ********/
/****************************************************************************************/
  static createCategory(payload, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/menu/category`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
  static modifyCategory(payload, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/menu/category`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
  static deleteCategory(payload, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/menu/category`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
/****************************************************************************************/
/******** Product ********/
/****************************************************************************************/
  static createStandardProduct(data, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/menu/product`, {
      method: 'POST',
      body: data
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
  static updateStandardProduct(data, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/menu/product/edit`, {
      method: 'POST',
      body: data,
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
  static changeVisibleProduct(payload, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/menu/product`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
  static deleteProduct(payload, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/menu/product`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
}
