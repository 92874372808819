import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { ValidatedTextField } from '../../../../controls/ValidatedTextField';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import addCategoryIngredient from '../../../../redux/actions/customProduct/addCategoryIngredient';
import editCategoryIngredient from '../../../../redux/actions/customProduct/editCategoryIngredient';
import { connect } from 'react-redux';

class CategoryIngredientDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.currentCategory ? this.props.currentCategory.name : '',
      nameValid: false,

      isDialogOpen: false
    };
  }
  resetValues = () => { this.setState({ name: '', nameValid: false }) }
  /** Events **/
  onCreateNewCategory = () => {
    this.props.onCloseDialog();
    if( this.props.modeEdition )
      this.props.editCategoryIngredient({ category: this.props.currentCategory, newName: this.state.name });
    else
      this.props.addCategoryIngredient({ name: this.state.name, visible: false, ingredients: [] });
    this.resetValues();
  }
  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onCloseDialog} aria-labelledby="modal-item-standard" aria-describedby="modal-item-standard">
        <DialogTitle id="alert-dialog-slide-title">{this.props.modeEdition ? 'Editar Categoría' : 'Crear Categoría' }</DialogTitle>
        <DialogContent>
          <ValidatedTextField label="nombre" defaultValue={this.state.name} validations={[ {type: 'isRequired', msg: 'El nombre es requerido'} ]} onChange={(newValue) => { this.setState({ name: newValue }); }} onValidate={(isValid) => {this.setState({ nameValid: isValid}); }}></ValidatedTextField>
        </DialogContent>
        <DialogActions>
          <div className="container-right">
            <Button color="primary" variant="contained" size="small" disabled={!this.state.nameValid} onClick={this.onCreateNewCategory}> <AddIcon /> Siguiente </Button>
            <Button color="secondary" variant="contained" size="small" onClick={this.props.onCloseDialog}> Atrás </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ( {} );

const mapDispatchToProps = {
  addCategoryIngredient,
  editCategoryIngredient,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryIngredientDialog);