import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { departamentosYMunicipiosCombined } from '../hardcodedData/departamentosYMunicipiosColombia';

class DropdownMultipleDepartamentoYMunicipio extends React.Component {
  constructor(props) {
    super(props);
    let optionsToExclude = this.props.optionsToExclude || [];
    this.state = {
      value: this.props.defaultValue || '',
      isDirty: false,
      isvalid: false,
      optionsToSelect: departamentosYMunicipiosCombined.filter((option) => { return optionsToExclude.indexOf(option) === -1 })
    };
  }
  componentDidMount() {
    this.validate(this.props.defaultValue);
  }
  validate() {
    const selectedOptions = this.state.value;
    const isValid = selectedOptions ? selectedOptions.length > 0: false;
    this.setState({
      hasError: !isValid && (this.state.isDirty || this.state.value.length > 0),
      errorMessage: !isValid && this.state.isDirty ? (this.props.errorMessage || 'Debes estar dentro de una zona de cobertura') : '',
      isSuccessful: isValid && (this.state.isDirty || this.state.value.length > 0)
    });
    if (this.props.onValidate)
      this.props.onValidate(isValid);
  }
  render() {
    return (
      <Autocomplete
        style={{ width: '100%' }}
        multiple
        id="departamento-municipio-multi-select"
        size="small"
        options={this.state.optionsToSelect}
        getOptionLabel={(option) => option}
        defaultValue={this.props.defaultValue}
        renderInput={(params) => (
          <TextField helperText={this.state.errorMessage} error={this.state.hasError} {...params} variant="outlined" label="Departamento, Ciudad / Municipio" placeholder="Ciudad / Municipio" />
        )}
        onChange={(event, selectedOptions, operation) => {
          this.setState({ value: selectedOptions });
          this.props.onChange(selectedOptions);
          this.validate();
        }}
        onBlur={() => {
          this.setState({ isDirty: true }, () => {
            this.validate();
          });
        }}
        className={this.state.isSuccessful ? 'input-correct' : ''}
      />
    );
  }
}

export default DropdownMultipleDepartamentoYMunicipio;