import React from 'react';
import { IconButton, List, ListItem } from '@material-ui/core';
import {
  Button,
} from '@material-ui/core';
import CoverageZone from './CoverageZone';
import DeleteIcon from '@material-ui/icons/Delete';

class CoverageZones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coverageZones: this.props.defaultCoverageZones || [],
    }
  }
  validate = () => {
    var coverageZones = this.state.coverageZones;
    var validCoverageZones = this.state.coverageZones.map((coverageZone) => coverageZone.isValid);
    this.props.onValidate(coverageZones.length === validCoverageZones.length);
  }
  render() {
    var zonesToExclude = [];
    for (var i = 0; i < this.state.coverageZones.length; i++) {
      zonesToExclude = zonesToExclude.concat(this.state.coverageZones[i].coverageZone);
    }
    return (
      <>
        <div className="container-right">
          <Button color="primary" variant="contained" size="small" onClick={() => {
            var coverageZones = this.state.coverageZones;
            coverageZones.push({});
            this.setState({ coverageZones: coverageZones });
          }}>Adicionar zona de cobertura</Button>
        </div>
        <List>
          {this.state.coverageZones.map((zone, index) => {
            let {coverageZone, shippingPrice} = zone;
            return (
              <ListItem style={{ display: 'block', }} key={ index }>
                <CoverageZone
                  zonesToExclude={zonesToExclude}
                  defaultCoverageZone={coverageZone}
                  defaultShippingPrice={shippingPrice}
                  index={index}
                  onChangeCoverageZone={({ index, coverageZone, shippingPrice }) => {
                    var coverageZones = this.state.coverageZones;
                    coverageZones[index] = { coverageZone, shippingPrice };
                    this.setState({ coverageZones: coverageZones }, () => this.props.onChange(coverageZones) );
                  }}
                  onValidate = {(isValid) => {
                    this.validate();
                  }} />
                <div className="container-right">
                  <IconButton aria-label="delete-category" onClick={() => {
                    var coverageZones = this.state.coverageZones;
                    coverageZones.splice(index, 1);
                    this.setState({ coverageZones: [] }, () => {
                      this.setState({ coverageZones: coverageZones }, () => {
                        this.props.onChange(coverageZones);
                        this.forceUpdate();
                      });
                    });
                  }}> <DeleteIcon fontSize="large" /> </IconButton>
                </div>
              </ListItem>
            );
          })}
        </List>
      </>
    );
  }
}

export default CoverageZones;
