import React from 'react';
import { List, ListItem, Button } from '@material-ui/core';
import ProductCategory from './categoryOfProduct/ProductCategory';
import { VisibleButton } from '../../controls/VisibleButton';
import CategoryProductDialog from './categoryOfProduct/CategoryProductDialog';
import { connect } from 'react-redux';
import { createMenu, changeVisibleMenu } from '../../redux/actions/menu/menuActions';
import { CircularProgress } from '@material-ui/core';

class MyMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
    }
    this.props.createMenu(sessionStorage.getItem('UID'));
  }
  onSetVisible = (isVisible) => {
    this.props.changeVisibleMenu({ UID: sessionStorage.getItem('UID'), visible: isVisible});
  }
  checkIsValid() {
    const isValid = this.props.myMenu && this.props.myMenu.categories && this.props.myMenu.categories.length > 0;
    this.props.onChangeIsValid(isValid);
  }
  render() {
    let { myMenu } = this.props;
    const menuVisible = myMenu ? myMenu.visible : false;
    this.checkIsValid();
    return (
      <>
        {myMenu &&
          <div className="content">
            <List>
              <ListItem className="container-space-between"> <h3>{this.props.title}</h3> </ListItem>
              <ListItem>Habilita tus productos para que tus usuarios los puedan ver<VisibleButton visible={menuVisible} onChange={this.onSetVisible} /> </ListItem>
            </List>
            {!myMenu.categories && 
              <List style={ { textAlign: 'center' } }>
                <CircularProgress size={24} />
              </List>
            }
            <List>
              {myMenu && myMenu.categories && myMenu.categories.map((category, index) => {
                return (
                  <ListItem key={ category.id }>
                    <ProductCategory metaMenu={ myMenu } category={ category } />
                  </ListItem>
                );
              })}
            </List>

            <List>
              <ListItem className="container-center">
                <Button color="primary" variant="contained" size="small" onClick={() => { this.setState({ openDialog: true }); }}> Agregar Categoría </Button>
                <CategoryProductDialog open={this.state.openDialog} modeEdition={false} onCloseDialog = { () => { this.setState({ openDialog: false }); } } />
              </ListItem>
            </List>
          </div>
        }
      </>
    );
  }
}

const mapStateToProps = state => ({
  myMenu: state.myMenu,
});

const mapDispatchToProps = {
  changeVisibleMenu,
  createMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMenu);