import React from 'react';
import MyMenu from './MyMenu';

export class EditMyMenuScreen extends React.Component {
  render() {
    return (
      <div className="container">
        <MyMenu title={"Mis productos"} onChangeIsValid={() => {}} />
      </div>
    );
  }
}