import { type as addCategoryIngredientType } from '../actions/customProduct/addCategoryIngredient';
import { type as deleteCategoryType } from '../actions/customProduct/deleteCategory';
import { type as changeVisibleCategoryOfIngredientsType } from '../actions/customProduct/changeVisibleCategoryOfIngredients';
import { type as changeVisibleIngredientType } from '../actions/customProduct/changeVisibleIngredient';
import { type as addIngredientType } from '../actions/customProduct/addIngredient';
import { type as createCustomProductType } from '../actions/customProduct/createCustomProduct';
import { type as editCategoryIngredientType } from '../actions/customProduct/editCategoryIngredient';
import update from 'immutability-helper';

const defaultState = {
  visible: false,
  name: '',
  categories: []
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    /** Custom Product **/
    case createCustomProductType: {
      return update(state, {
        name: {$set: payload}
      });
    }
    /** Categories **/
    case addCategoryIngredientType: {
      return update(state, {
        categories: {$push: [payload]}
      });
    }
    case editCategoryIngredientType: {
      const { category, newName } = payload;
      const indexToUpdate = state.categories.indexOf(category);
      return update(state, {
        categories: {
          [indexToUpdate]: {
            name: {$set: newName}
          }
        }
      });
    }
    case deleteCategoryType: {
      return update(state, {
        categories: {$set: state.categories.filter(c => c !== payload)}
      });
    }
    case changeVisibleCategoryOfIngredientsType: {
      const { category, isVisible } = payload;
      const indexToUpdate = state.categories.indexOf(category);
      return update(state, {
        categories: {
          [indexToUpdate]: {
            visible: {$set: isVisible}
          }
        }
      });
    }
    /** Ingredients **/
    case addIngredientType: {
      const { category, ingredient } = payload;
      const indexToUpdate = state.categories.indexOf(category);
      return update(state, {
        categories: {
          [indexToUpdate]: {
            ingredients: {$push: [ingredient]}
          }
        }
      });
    }
    case changeVisibleIngredientType: {
      const { category, ingredient, isVisible } = payload;
      const catIndexToUpdate = state.categories.indexOf(category);
      const prodIndexToUpdate = state.categories[catIndexToUpdate].ingredients.indexOf(ingredient);
      return update(state, {
        categories: {
          [catIndexToUpdate]: {
            ingredients: {
              [prodIndexToUpdate]: {
                visible: {$set: isVisible}
              }
            }
          }
        }
      });
    }
    default:
      return state;
  }
}

export default reducer;