import React, { useState } from 'react';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import green from '@material-ui/core/colors/green';

export const VisibleButton = (props) => {
  const [visible, setVisible] = useState(props.visible);
  return (
    <BottomNavigationAction
      showLabel
      style={ visible ? { color: green[500] } : {} }
      label={ visible ? "Visible" : "Invisible" } icon={ visible ? <ToggleOnIcon /> : <ToggleOffIcon /> }
      onClick={ () => { setVisible(!visible); props.onChange(!visible); } }
    />
  );
}