import React from 'react';
import QRCode from 'qrcode.react';
import logoAlacuenta from '../../img/Alacuenta-logo.png';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';

class PosterToPrint extends React.Component {
  render() {
    return (
      <div className='containerPrintVerson'>
        <div>
          <img src={`${this.props.restaurantInitialSteps.basicInfo.photoURL}`} alt="logo-alacuenta" width={200} height={200} />
          <h1>{this.props.restaurantInitialSteps.basicInfo.name}</h1>
        </div>
        <h3>Escanea el siguiente código QR con tu celular para realizar tus domicilio:</h3>
        <div className="container-QR">
          <QRCode value={this.props.URL_TO_SHARE} />
        </div>
        <h3>o accede al sigueinte link:</h3>
        <h3>{this.props.URL_TO_SHARE}</h3>
        <div className='printed-logo-alacuenta'>
          <Typography className="title" component="div">
            <Link color="inherit" className="logo-alacuenta">
              <img src={logoAlacuenta} alt="logo-alacuenta" width={50} height={50} />
            </Link>
              <div className="title-container">
                <h1 className="title-app"> alacuenta </h1>
                <span className="title-app subtitle"> .com </span>
              </div>
          </Typography>
        </div>
      </div>
    );
  }
}

export default PosterToPrint;