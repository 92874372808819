import React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import RestaurantBasicInfo from '../restaurant/basicInfo/RestaurantBasicInfo';
import RestaurantLocationInfo from '../restaurant/locationInfo/RestaurantLocationInfo';
import RestaurantPaymentInfo from '../restaurant/paymentInfo/RestaurantPaymentInfo';
import MyMenu from '../menu/MyMenu';
import getRestaurant from '../../redux/actions/restaurantInitialSteps/getRestaurant';
import setupComplete from '../../redux/actions/restaurantInitialSteps/setupComplete';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import PosterToPrint from '../printedVersions/PosterToPrint';
import { CircularProgress } from '@material-ui/core';
import { ALACUENTA_DOMAIN } from '../../config-front';

class InitialStepper extends React.Component {
  stepRefs = [];
  constructor(props) {
    super(props);
    const intitialSteps = [
      <RestaurantBasicInfo ref={control => this.stepRefs[0] = control} onChangeIsValid = {(isValid) => { this.changeValidStep(0, isValid) }} />,
      <RestaurantLocationInfo ref={control => this.stepRefs[1] = control} onChangeIsValid = {(isValid) => { this.changeValidStep(1, isValid) }} onChange = {() => {}} />,
      <RestaurantPaymentInfo ref={control => this.stepRefs[2] = control} />,
      <MyMenu ref={control => this.stepRefs[3] = control} title={`Paso 4: Crea tu lista de productos`} onChangeIsValid = {(isValid) => { this.changeValidStep(3, isValid) }} />
    ];
    this.state = {
      steps: intitialSteps,
      activeStep: 0,
      validSteps: [false, false, true, false],
      maxSteps: intitialSteps.length,

      restaurantQueried: false,

      isTextCopied: false,

      loadingNextStep: false,
    };
    this.getRestaurantInfo();
    this.linkRef = React.createRef();
    this.URL_TO_SHARE = null;
  }
  getRestaurantInfo() {
    this.props.getRestaurant(sessionStorage.getItem('UID'), () => {
      this.setCurrentStep();
      this.setState({ restaurantQueried: true });
    });
  }
  handleNext = () => {
    this.setState({ loadingNextStep: true });
    this.stepRefs[this.state.activeStep].onSave(() => {
      this.setState({
        loadingNextStep: false,
        activeStep: this.state.activeStep + 1
      });
    });
  };
  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };
  handleComplete = () => {
    this.props.setupComplete({ UID: sessionStorage.getItem('UID') });
  }
  changeValidStep = (index, isValid) => {
    let newValidSteps = this.state.validSteps;
    newValidSteps[index] = isValid;
    this.setState({ validSteps: newValidSteps });
  };
  setCurrentStep = () => {
    if (this.props.restaurantInitialSteps.basicInfo) {
      this.setState({ activeStep: 1 });
      if (this.props.restaurantInitialSteps.locationInfo) {
        this.setState({ activeStep: 2 });
      }
    }
  }
  copyToClipboard = (e) => {
    var txtToCopy = document.getElementById("txtToCopy");
    txtToCopy.select();
    txtToCopy.setSelectionRange(0, 99999);
    document.execCommand('copy');
    this.setState({ isTextCopied: true }, () => {
      setTimeout(() => {
        this.setState({ isTextCopied: false });
      }, 500);
    });
  };

  render() {
    if(this.props.restaurantInitialSteps.basicInfo) {
      this.URL_TO_SHARE = `${ALACUENTA_DOMAIN}/#/${this.props.restaurantInitialSteps.basicInfo.alias}`;
    }
    return (
      <>
        { this.state.restaurantQueried === false && 
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <CircularProgress size={24} />
          </div>
        }
        { this.state.restaurantQueried && this.props.restaurantInitialSteps.setupComplete === true ?
          <>
            <div className='container'>
              <p>Este es el link para que compartas con tus clientes:</p>
              <div style={{ border: '1px solid #ccc', borderRadius: 5 }}>
                <input readOnly style={{ border: 'none', width: 450 }} id="txtToCopy" ref={this.linkRef} type="text" value={this.URL_TO_SHARE}/>
                <a style={{ padding: 10, float: 'right' }} href={this.URL_TO_SHARE} target='_blank' rel="noopener noreferrer">Abrir</a>
                <Button style={{ color: this.state.isTextCopied ? '#f00' : '#333' }} onClick={this.copyToClipboard} variant="contained">
                  {this.state.isTextCopied ? 'Copiado!' : 'Copiar'}
                </Button>
              </div>
              <div>
                Imprime o comparte este código con tus clientes:
              </div>
              <div className="container-QR" style={{ textAlign: 'center' }}>
                <QRCode value={this.URL_TO_SHARE} />
              </div>
            </div>
            <ReactToPrint
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return (
                  <div className="container-QR">
                    <Button variant="contained">
                      Imprimir
                    </Button>
                  </div>
                );
              }}
              content={() => this.componentRef}
            />
            <div style={{ display: 'none' }}>
              <PosterToPrint
                restaurantInitialSteps={this.props.restaurantInitialSteps}
                URL_TO_SHARE={this.URL_TO_SHARE}
                ref={el => (this.componentRef = el)}
              />
            </div>
          </>
          :
          <>
            { (this.props.restaurantInitialSteps || this.props.restaurantInitialSteps.setupComplete === false) &&
              <div className='container'>
                { this.state.restaurantQueried && this.state.steps[this.state.activeStep] }
                <MobileStepper
                  steps={this.state.maxSteps}
                  position="static"
                  variant="text"
                  activeStep={this.state.activeStep}
                  nextButton={
                    <>
                    {this.state.activeStep < this.state.maxSteps - 1 && 
                      <Button size="small" onClick={this.handleNext} disabled={!(this.state.validSteps[this.state.activeStep] && !this.state.loadingNextStep)}>
                        {this.state.loadingNextStep && <CircularProgress size={24} />} Siguiente {<KeyboardArrowRight />}
                      </Button>
                    }
                    {this.state.activeStep === this.state.maxSteps - 1 && 
                      <Button size="small" onClick={this.handleComplete} disabled={!this.state.validSteps[this.state.activeStep]}>
                        Finalizar {<KeyboardArrowRight />}
                      </Button>
                    }
                    </>
                  }
                  backButton={
                    <Button size="small" onClick={this.handleBack} disabled={this.state.activeStep === 0}>
                      {<KeyboardArrowLeft />} Atrás
                    </Button>
                  }
                />
              </div>
            }
          </>
        }
      </>
    );
  }
}

const mapStateToProps = state => ({
  restaurantInitialSteps: state.restaurantInitialSteps,
  myMenu: state.myMenu,
});

const mapDispatchToProps = {
  getRestaurant,
  setupComplete,
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialStepper);