import { type as getRestaurantType } from '../actions/restaurantInitialSteps/getRestaurant';
import { type as setRestaurantBasicInfoType } from '../actions/restaurantInitialSteps/setRestaurantBasicInfo';
import { type as setRestaurantLocationInfoType } from '../actions/restaurantInitialSteps/setRestaurantLocationInfo';
import update from 'immutability-helper';

const defaultState = {};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    /** Get Restaurant **/
    case getRestaurantType: {
      return update(state, { $set: payload });
    }
    /** Set Restaurant Basic Info **/
    case setRestaurantBasicInfoType: {
      return update(state, {
        basicInfo: {$set: payload}
      });
    }
    /** Set Restaurant Basic Info **/
    case setRestaurantLocationInfoType: {
      return update(state, {
        locationInfo: {$set: payload}
      });
    }
    default:
      return state;
  }
}

export default reducer;