import React, { Component } from 'react';
import InitialStepper from './InitialStepper';

class Dashboard extends Component {
  render() {
    return (
      <InitialStepper />
    );
  }
}

export default Dashboard;