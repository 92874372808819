import RestaurantDAO from '../../../DAO/RestaurantDAO';

export const type = 'setRestaurantLocationInfo';

const setRestaurantLocationInfo = ( { coverageZones, UID }, callback ) => {
    return (dispatch, getState) => {
        RestaurantDAO.saveLocationInfo(coverageZones, UID, (menu) => {
            callback();
            dispatch( { type: type, payload: { coverageZones } } )
        });
    }
}

export default setRestaurantLocationInfo;