import React from 'react';
import { ValidatedTextField } from '../../../controls/ValidatedTextField';
import DropdownMultipleDepartamentoYMunicipio from '../../../controls/DropdownMultipleDepartamentoYMunicipio';

class CoverageZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coverageZone: this.props.defaultCoverageZone || [],
      shippingPrice: this.props.defaultShippingPrice || '',

      coverageZoneValid: false,
      shippingPriceValid: false,
    };
  }
  /** Events **/
  onChange = () => {
    this.props.onChangeCoverageZone({
      index: this.props.index,
      coverageZone: this.state.coverageZone,
      shippingPrice: this.state.shippingPrice,
    });
  }
  validate = () => {
    this.props.onValidate(this.state.coverageZoneValid && this.state.shippingPriceValid);
  }
  checkIsValid() {
    this.props.onChangeIsValid(this.state.coverageZoneValid && this.state.shippingPriceValid);
  }
  render() {
    return (
      <>
        <div>
          <DropdownMultipleDepartamentoYMunicipio
            optionsToExclude={this.props.zonesToExclude}
            defaultValue={this.state.coverageZone}
            errorMessage="Selecciona al menos una zona de cobertura"
            onChange = {(selectedOptions) => {
              this.setState( { coverageZone: selectedOptions }, this.onChange );
            }}
            onValidate={(isValid) => { this.setState({ coverageZoneValid: isValid }, this.validate ); }} />
        </div>
        <div>
          <ValidatedTextField label="precio del domicilio" defaultValue={this.state.shippingPrice} validations={[ {type: 'isRequired', msg: 'El precio del domicilio es requerido'}, {type: 'money', msg: 'Ingrese un precio válido'} ]} onChange={(newValue) => { this.setState({ shippingPrice: newValue }, this.onChange ); }} onValidate={(isValid) => {
            this.setState( { shippingPriceValid: isValid }, this.validate );
          }}></ValidatedTextField>
        </div>
      </>
    );
  }
}

export default CoverageZone;
