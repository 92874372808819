import MyMenuDAO from '../../../DAO/MyMenuDAO';

export const setMenuType = 'SET_MENU';

/****************************************************************************************/
/******** Menu ********/
/****************************************************************************************/
export const createMenu = (UID) => {
  return (dispatch, getState) => {
    MyMenuDAO.createMenu(UID, (menu) => {
      dispatch( { type: setMenuType, payload: { menu } } );
    });
  }
}
export const clearMenu = () => {
  return (dispatch, getState) => {
    dispatch( { type: setMenuType, payload: { menu: {} } } );
  }
}
export const changeVisibleMenu = ({ UID, visible }) => {
  return (dispatch, getState) => {
      const payload = { UID, visible };
      MyMenuDAO.changeVisibleMenu(payload, (menu) => {
        dispatch( { type: setMenuType, payload: { menu } } );
      });
  }
}
/****************************************************************************************/
/******** Category ********/
/****************************************************************************************/
export const addCategory = ({ categoryName, UID }) => {
  return (dispatch, getState) => {
      const payload = { UID, categoryName };
      MyMenuDAO.createCategory(payload, (menu) => {
          dispatch( { type: setMenuType, payload: { menu } } );
      });
  }
}
export const changeVisibleCategory = ({ UID, categoryID, visible }) => {
  return (dispatch, getState) => {
    const payload = { UID, categoryID, visible };
    MyMenuDAO.modifyCategory(payload, (menu) => {
      dispatch( { type: setMenuType, payload: { menu } } );
    });
  }
}
export const changeNameCategory = ({ UID, categoryID, name }) => {
  return (dispatch, getState) => {
    const payload = { UID, categoryID, name };
    MyMenuDAO.modifyCategory(payload, (menu) => {
      dispatch( { type: setMenuType, payload: { menu } } );
    });
  }
}
export const deleteCategory = ({ UID, categoryID }) => {
  return (dispatch, getState) => {
    const payload = { UID, categoryID };
    MyMenuDAO.deleteCategory(payload, (menu) => {
      dispatch( { type: setMenuType, payload: { menu } } );
    });
  }
}
/****************************************************************************************/
/******** Product ********/
/****************************************************************************************/
export const addStandardProduct = ({ UID, categoryID, product, mainPhoto, photos }, resolve) => {
  return (dispatch, getState) => {
    var data = new FormData();
    data.append('UID', UID);
    data.append('categoryID', categoryID);
    data.append('product', JSON.stringify(product));
    if (mainPhoto) {
      data.append('mainPhoto', mainPhoto);
    }
    if (photos && photos.length > 0) {
      for(var i=0;i<photos.length;i++) {
        data.append('photos'+i, photos[i]);
      }
    }
    MyMenuDAO.createStandardProduct(data, (menu) => {
      resolve(menu);
      dispatch( { type: setMenuType, payload: { menu } } );
    });
  }
}
export const updateStandardProduct = ({ UID, categoryID, productID, product, mainPhoto, photos }, resolve) => {
  return (dispatch, getState) => {
    var data = new FormData();

    data.append('UID', UID);
    data.append('categoryID', categoryID);
    data.append('productID', productID);
    data.append('product', JSON.stringify(product));
    if (mainPhoto)
      data.append('mainPhoto', mainPhoto);
    if (photos && photos.length > 0) {
      for(var i=0;i<photos.length;i++) {
        data.append('photos'+i, photos[i]);
      }
    }
    MyMenuDAO.updateStandardProduct(data, (menu) => {
      resolve(menu);
      dispatch( { type: setMenuType, payload: { menu } } );
    });
  }
}
export const changeVisibleProduct = ({ UID, categoryID, productID, visible }) => {
  return (dispatch, getState) => {
    const payload = { UID, categoryID, productID, visible };
    MyMenuDAO.changeVisibleProduct(payload, (menu) => {
      dispatch( { type: setMenuType, payload: { menu } } );
    });
  }
}

export const deleteProduct = ({ UID, categoryID, productID }) => {
  return (dispatch, getState) => {
    const payload = { UID, categoryID, productID };
    MyMenuDAO.deleteProduct(payload, (menu) => {
      dispatch( { type: setMenuType, payload: { menu } } );
    });
  }
}

