import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { VisibleButton } from '../../../../controls/VisibleButton';
import deleteCategory from        '../../../../redux/actions/customProduct/deleteCategory';
import changeVisibleCategoryOfIngredients from '../../../../redux/actions/customProduct/changeVisibleCategoryOfIngredients';
import changeVisibleIngredient from '../../../../redux/actions/customProduct/changeVisibleIngredient';
import { connect } from 'react-redux';
import IngredientCreatorButton from '../ingredients/IngredientCreatorButton';
import CategoryIngredientDialog from './CategoryIngredientDialog';

class IngredientCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCategoryDialog: false,
    };
  }
  render() {
    const { category } = this.props;
    return (
      <>
        <Card className="container">
          <CardHeader title={category.name} style={{ cursor: 'pointer' }} onClick={() => { this.setState({ openCategoryDialog: true }); }} />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="div">
              <List>
                {category.ingredients.map((ingr, index) => (
                  <ListItem key={index} className="container-space-between">
                    <span>{ingr.name}</span> <VisibleButton onChange={isVisible => {
                      this.props.changeVisibleIngredient({ category: category, ingredient: ingr, isVisible: isVisible });
                      }} />
                  </ListItem>
                ))}
              </List>
            </Typography>
          </CardContent>
          <CardActions disableSpacing className="container-space-around">
            <IconButton aria-label="delete-category" onClick={ () => { this.props.deleteCategory(category); } }> <DeleteIcon fontSize="large" /> </IconButton>
            <VisibleButton onChange={ isVisible => { 
              this.props.changeVisibleCategoryOfIngredients({ category, isVisible }); 
            } } />
            <IngredientCreatorButton category={category}/>
          </CardActions>
        </Card>

        <CategoryIngredientDialog open={this.state.openCategoryDialog} modeEdition={true} currentCategory={ category } onCloseDialog = { () => { this.setState({ openCategoryDialog: false }); } } />
      </>
    ); 
  }
}

const mapStateToProps = state => ( {} );

const mapDispatchToProps = {
  deleteCategory,
  changeVisibleCategoryOfIngredients,
  changeVisibleIngredient,
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientCategory);