import React from 'react';
import {
  Button,
  Menu,
} from '@material-ui/core';
import StandardProductButton from './productStandard/StandardProductButton';

export class AddProductButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newProductMenuOpener: null,
    };
  }
  /** Events **/
  handleOpenNewItem = (event) => { this.setState({ newProductMenuOpener: event.currentTarget }); }
  handleCloseNewItem = () => { this.setState({ newProductMenuOpener: null }); }
  
  render() {
    const { category } = this.props; 
    return (
      <>
        <Button color="primary" variant="contained" size="small" onClick={this.handleOpenNewItem}> Agregar Producto </Button>
        
        <Menu id="add-item-menu" anchorEl={this.state.newProductMenuOpener} keepMounted open={Boolean(this.state.newProductMenuOpener)} onClose={this.handleCloseNewItem}>
          <StandardProductButton modeEdition={false} category={category} onClick={this.handleCloseNewItem} />
          {/**
          <CustomProductCreatorButton category={category} onClick={this.handleCloseNewItem} />
          **/}
        </Menu>
      </>
    );
  }
}