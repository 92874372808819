export const secureFetch = (url, config) => {
  return new Promise((resolve, reject) => {
    fetch(url, config).then(response => {
      // response only can be ok in range of 2XX
      response.json().then(function (data) {
        if (response.ok) {
          resolve({ ok: response.ok, status: response.status, content: data });
        }
        else {
          reject({ ok: response.ok, status: response.status, content: data });
        }
      });
    })
    .catch(error => {
      //it will be invoked mostly for network errors
      //do what ever you want to do with error here
      console.log(error);
      reject(error);
    });
  });
};