import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { connect } from 'react-redux';
import PaymentMethodForm from './PaymentMethodForm';

class AddPaymentMethodButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      loading: false,

      formIsValid: false,

      paymentMethod: null,
    };
  }
  handleOpenDialog = () => {
    this.setState({ isDialogOpen: true });
  }
  onCloseDialog = () => {
    this.setState({ isDialogOpen: false });
  }
  onChangeIsValid = (isValid) => {
    this.setState({ formIsValid: isValid });
    if(!isValid)
      this.setState({ paymentMethod: null, QrCodeImage: null });
  }
  onCompletePaymentMethod = (paymentMethod, QrCodeImage) => {
    this.setState({ paymentMethod: paymentMethod, QrCodeImage: QrCodeImage });
  }
  handleAddPaymentMethod = () => {
    this.onCloseDialog();
    this.props.onCreatePaymentMethod(this.state.paymentMethod, this.state.QrCodeImage);
  }
  render() {
    return (
      <>
        <Button color="primary" variant="contained" size="small" onClick={this.handleOpenDialog}>Agregar medio de pago</Button>
        <Dialog className="container dialog-window" open={this.state.isDialogOpen} onClose={this.onCloseDialog} aria-labelledby="modal-item-standard" aria-describedby="modal-item-standard">
          <DialogTitle id="alert-dialog-slide-title">Agregar medio de pago</DialogTitle>
          <DialogContent>
            <PaymentMethodForm restaurantInitialSteps = {this.props.restaurantInitialSteps} onChangeIsValid = {this.onChangeIsValid} onCompletePaymentMethod = {this.onCompletePaymentMethod} />
          </DialogContent>
          <DialogActions>
            <div className="container-right">
              <Button color="primary" variant="contained" size="small" disabled={!this.state.formIsValid} onClick={this.handleAddPaymentMethod}>
                {this.state.loading && <CircularProgress size={24} />}
                <AddIcon /> {'Agregar'}
              </Button>
              <Button color="secondary" variant="contained" size="small" onClick={this.onCloseDialog}> Cancelar </Button>
            </div>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddPaymentMethodButton);

