import React from 'react';
import { formatMoney } from '../controls/Formatter';
import Typography from '@material-ui/core/Typography';
import { VisibleButton } from './VisibleButton';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import { changeVisibleProduct } from '../redux/actions/menu/menuActions';
import StandardProductButton from '../components/menu/productStandard/StandardProductButton';

const Product = (...props) => {
  const rest = props[0];
  const product = rest.product;
  const category = rest.category;
  const changeVisibleProduct = rest.changeVisibleProduct;
  const handleCloseEditItem = rest.handleCloseEditItem;
  return (
    <>
      {/*
      <ListItem key={index} className={`container-space-between ${prod.visible ? '': 'product-disabled' }`}>
        <StandardProductButton buttonName={prod.name} modeEdition={true} category={category} product={prod} onClick={this.handleCloseEditItem} />
        <div>
          <span>{formatMoney(prod.price)}</span>
          <VisibleButton visible={prod.visible} onChange={visible => {
            this.props.changeVisibleProduct({ UID: sessionStorage.getItem('UID'), categoryID: category.id, productID: prod.id, visible });
          }} />
          <IconButton aria-label="delete-product" onClick={() => { this.setState({openConfirmation: true, messageConfirmation: '¿Estás seguro que deseas eliminar este producto?', callbackConfirmation: () => { this.props.deleteProduct({ UID: sessionStorage.getItem('UID'), categoryID: category.id, productID: prod.id })} }); } }> <DeleteIcon fontSize="large" /> </IconButton>
        </div>
      </ListItem>
      */}
      <div className="product-element" key={`${product.name}`}>
        <div className="content-left">
          <img src={product.photoURL} alt={product.name} title={product.name} />
          <div>
            <StandardProductButton buttonName={product.name} modeEdition={true} category={category} product={product} onClick={handleCloseEditItem} />
            <Typography color="textSecondary" gutterBottom>
                {formatMoney(product.price)}
            </Typography>
            <VisibleButton visible={product.visible} onChange={visible => {
              changeVisibleProduct({ UID: sessionStorage.getItem('UID'), categoryID: category.id, productID: product.id, visible });
            }} />
            <IconButton aria-label="delete-product" onClick={() => {
              rest.onDeleteProduct();
            } }> <DeleteIcon fontSize="large" /> </IconButton>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ( {} );

const mapDispatchToProps = {
  changeVisibleProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
