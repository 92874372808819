import React from 'react';
import CustomProduct from './CustomProduct';

export class CustomProductScreen extends React.Component {
  render() {
    return (
      <div className="container">
        <CustomProduct title={"Crear producto personalizado"} />
      </div>
    );
  }
}