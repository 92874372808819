import React from 'react';
import AddPaymentMethodButton from './AddPaymentMethodButton';
import { connect } from 'react-redux';
import { IconButton, List, ListItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { setRestaurantPaymentInfo, changeVisiblePaymentMethod, deletePaymentMethod } from '../../../redux/actions/restaurantInitialSteps/paymentInfoActions';
import { VisibleButton } from '../../../controls/VisibleButton';

class RestaurantLocationInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethods: this.props.restaurantInitialSteps.paymentMethods ? this.props.restaurantInitialSteps.paymentMethods : []
    };
  }
  onCreatePaymentMethod = (paymentMethod, QrCodeImage) => {
    this.setState({
      paymentMethods: this.state.paymentMethods.concat(paymentMethod)
    });
    this.props.setRestaurantPaymentInfo({
      paymentMethod: paymentMethod,
      QrCodeImage: QrCodeImage,
      UID: sessionStorage.getItem('UID')
    });
  }
  onSave(callback) {
    callback();
  }
  render() {
    return (
      <div className="content">
        <h3>Paso 3: Información de pago</h3>
        <div>
          ¿Tienes una cuenta para recibir tus pagos? Adiciónala o puedes dar clic en siguiente, siempre la podrás editar en la sección "Mi Cuenta".
        </div>
        <p></p>
        <AddPaymentMethodButton restaurantInitialSteps = {this.props.restaurantInitialSteps} onChangeIsValid = {this.props.onChangeIsValid} onCreatePaymentMethod = {this.onCreatePaymentMethod} />
        <List>
          {this.state.paymentMethods.map((paymentMethod, index) => {
            return (
              <ListItem style={{ display: 'block', }} key={ index }>
                <div>Banco: {paymentMethod.bankName}</div>
                <div>Titular: {paymentMethod.ownerName}</div>
                <div>Tipo de documento: {paymentMethod.documentType}</div>
                <div>Número de identificación: {paymentMethod.ownerIdentification}</div>
                {paymentMethod.accountNumber && 
                  <>
                    <div>Tipo de cuenta: {paymentMethod.accountType}</div>
                    <div>Número: {paymentMethod.accountNumber}</div>
                  </>
                }
                {paymentMethod.phoneNumber &&
                  <div>Número de teléfono: {paymentMethod.phoneNumber}</div>
                }
                {paymentMethod.QrCodeImage &&
                  <div>QR para pagos: Activo</div>
                }
                <div className="container-right">
                  <IconButton aria-label="delete-category" onClick={() => {
                    this.props.deletePaymentMethod({
                      UID: sessionStorage.getItem('UID'),
                      index: index
                    });
                    var paymentMethods = this.state.paymentMethods;
                    paymentMethods.splice(index, 1);
                    this.setState({ paymentMethods: [] }, () => {
                      this.setState({ paymentMethods: paymentMethods }, () => {
                        //this.props.onChange(paymentMethods);
                        this.forceUpdate();
                      });
                    });
                  }}> <DeleteIcon fontSize="large" /> </IconButton>
                  <VisibleButton visible={paymentMethod.visible} onChange={(isVisible) => {
                    this.props.changeVisiblePaymentMethod({
                      UID: sessionStorage.getItem('UID'),
                      index: index,
                      visible: isVisible
                    });
                  }} />
                </div>
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  restaurantInitialSteps: state.restaurantInitialSteps,
});

const mapDispatchToProps = {
  setRestaurantPaymentInfo,
  changeVisiblePaymentMethod,
  deletePaymentMethod
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RestaurantLocationInfo);