import React, { Component } from 'react';
import './App.css';
import Login from './components/login/Login';
import AppToolBar from './controls/AppToolBar';
import { DASHBOARD, EDIT_MY_ACCOUNT, EDIT_MENU, EDIT_CUSTOM_PRODUCT, FAQ, CONTACT_PAGE } from './routes';
import {
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";
import Dashboard from './components/dashboard/Dashboard';
import withFirebaseAuth from 'react-with-firebase-auth'
import * as firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from './firebaseConfig';
import EditMyAccountScreen from './components/myAccount/EditMyAccountScreen';
import { EditMyMenuScreen } from './components/menu/EditMyMenuScreen';
import { CustomProductScreen } from './components/menu/productCustom/CustomProductScreen';
import { ThemeProvider } from '@material-ui/core/styles';
import alacuentaTheme from './themes/alacuentaTheme';
import FAQScreen from './components/faq/FAQScreen';
import ContactPageScreen from './components/contactPage/ContactPageScreen';

const firebaseApp = firebase.initializeApp(firebaseConfig);

class App extends Component {
  render() {
    const { user, signOut, signInWithGoogle } = this.props;
    sessionStorage.setItem('UID', user ? user.uid: undefined);
    return (
      <div>
        {
          user ?
          <ThemeProvider theme={alacuentaTheme}>
            <HashRouter>
              <Switch>
                <Route path={ EDIT_MY_ACCOUNT }>
                  <AppToolBar signOut = { signOut } user = { user } />
                  <EditMyAccountScreen />
                </Route>
                <Route path={ EDIT_MENU }>
                  <AppToolBar signOut = { signOut } user = { user } />
                  <EditMyMenuScreen />
                </Route>
                <Route path={ EDIT_CUSTOM_PRODUCT }>
                  <AppToolBar signOut = { signOut } user = { user } />
                  <CustomProductScreen />
                </Route>
                <Route path={FAQ}>
                <AppToolBar signOut = { signOut } user = { user } />
                  <FAQScreen />
                </Route>
                <Route path={CONTACT_PAGE}>
                  <AppToolBar signOut = { signOut } user = { user } />
                  <ContactPageScreen />
                </Route>
                <Route path={ DASHBOARD }>
                  <AppToolBar signOut = { signOut } user = { user } />
                  <Dashboard />
                </Route>
              </Switch>
            </HashRouter>
          </ThemeProvider>
          :
          <Login signInWithGoogle = { signInWithGoogle } />
        }
      </div>
    );
  }
}

const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
