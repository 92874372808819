import { SERVICE_PREFIX } from '../config-front';
import { secureFetch } from './secureFetch';

const service_prefix = SERVICE_PREFIX;
const PREFIX_ROLE = 'restaurant';

export default class RestaurantDAO {
  static saveBasicInfo(data, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/restaurants/basicInfo`, {
      method: 'POST',
      body: data,
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
  static saveLocationInfo(coverageZones, UID, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/restaurants/locationInfo`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ UID: UID, locationInfo: { coverageZones } }),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
      console.log('Success:', data);
    });
  }

  /** Payment Info **/
  static savePaymentInfo(data, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/restaurants/paymentInfo`, {
      method: 'POST',
      body: data
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
      console.log('Success:', data);
    });
  }
  static changeVisiblePaymentMethod(payload, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/restaurants/paymentInfo`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
  static deletePaymentMethod(payload, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/restaurants/paymentInfo`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }

  static saveBasicAndLocationInfo(data) {
    return secureFetch(`${service_prefix}/${PREFIX_ROLE}/restaurants`, {
      method: 'POST',
      body: data,
    });
  }

  static getRestaurant(UID, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/restaurants/${UID}`, {
      method: 'GET',
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
  static setupComplete(data, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/restaurants/setup-complete`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
}