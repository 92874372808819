import React from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Paper } from '@material-ui/core';
import Help from '@material-ui/icons/Help';
import { BACK_ESTABLECIMIENTOS } from '../../routes';

class FAQScreen extends React.Component {
  render() {
    return (
      <Paper className="container" style={{padding: 10}}>
        <h3>Preguntas frecuentes</h3>
        <List component="nav" aria-label="requent questions">
          <ListItem>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText
              primary="¿Qué es alacuenta.com?"
              secondary="Alacuenta.com es una plataforma online que permite a los negocios minoristas tales como restaurantes, tiendas, farmacias, ferreterias, variedades y otros mostrar un catálogo interactivo de sus productos para que los interesados en dichos productos puedan elegirlos y pedirlos de manera sencilla y rápida a través de WhatsApp facilitando y estandarizando la información de pedidos para el negocio."
              />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText
              primary="¿He realizado todos los pasos para registrar mis productos, pero no sé si ya quedó publicado, como puedo ver lo que está publicado y cuál es el link que debo compartir a mis clientes?"
              secondary={
                <>
                  Al finalizar todos los pasos, simplemente accede a <a href={ BACK_ESTABLECIMIENTOS } target='_blank' rel="noopener noreferrer">{ BACK_ESTABLECIMIENTOS }</a> y verás el link con un código QR para facilitar el acceso. Allí mismo podrás compartirlo en redes sociales e imprimirlo para que lo puedas pegar en tu establecimiento y empezar a recibir pedidos ;)
                </>
              }
              />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText
              primary="¿He realizado todos los pasos para registrar mis productos, pero quiero cambiar el nombre de una de mis categorías, como puedo hacerlo?"
              secondary="Dirígete a la opción de menú “Mis productos”. Debes hacer clic sobre el nombre de la categoría que quieres cambiar y te saldrá una opción para editar el nombre, escribe el nombre que quieras y luego da clic en guardar."
              />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText
              primary="¿Cómo puedo cambiar el número telefónico de whatsapp de mi negocio?"
              secondary="Una vez registrado ve al menú que aparece en la parte superior izquierda y haz clic en mi cuenta. Ahí encontrarás tus datos que podrás cambiar en cualquier momento. Una vez que modifiques cualquier dato deberás hacer clic en guardar para que el cambio se registre."
              />
          </ListItem>
          <Divider />
        </List>
      </Paper>
    );
  }
}

export default FAQScreen;
