import RestaurantDAO from '../../../DAO/RestaurantDAO';

export const type = 'setupComplete';

const setupComplete = (data) => {
  return (dispatch, getState) => {
    RestaurantDAO.setupComplete(data, (menu) => {
      //dispatch( { type: setMenuType, payload: { menu } } );
      dispatch( { type: type } );
      window.location.reload();
    });
    
  }
}

export default setupComplete;