import React from 'react';
import setRestaurantLocationInfo from '../../../redux/actions/restaurantInitialSteps/setRestaurantLocationInfo';
import { connect } from 'react-redux';
import CoverageZones from './CoverageZones';

class RestaurantLocationInfo extends React.Component {
  constructor(props) {
    super(props);
    let locationInfo = this.props.restaurantInitialSteps.locationInfo || {};
    this.state = {
      coverageZones: locationInfo.coverageZones || [],

      coverageZonesValid: false,
    };
  }
  onSave(callback) {
    this.props.setRestaurantLocationInfo({
      coverageZones: this.state.coverageZones,
      UID: sessionStorage.getItem('UID')
    }, callback);
  }
  /**
  checkIsValid() {
    if(this.props.onChange)
      this.props.onChange(this.state.selectedDepartamentosYMunicipios, this.state.shippingPrice);
    this.props.onChangeIsValid(this.state.departamentosYMunicipiosValid && this.state.shippingPriceValid);
  }
  **/
  render() {
    return (
      <div className="content">
        <h3>Paso 2: Ciudad de cobertura</h3>
        <p>Cuéntanos a qué ciudades y municipios puedes prestar tu servicio</p>
        <CoverageZones defaultCoverageZones={this.state.coverageZones.coverageZones} onValidate = {(isValid) => {
          this.props.onChangeIsValid(isValid);
        }}
        onChange = {(coverageZones) => {
          this.setState({ coverageZones: coverageZones }, () => this.props.onChange({ coverageZones }));
        }} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  restaurantInitialSteps: state.restaurantInitialSteps,
});

const mapDispatchToProps = {
  setRestaurantLocationInfo
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RestaurantLocationInfo);