import RestaurantDAO from '../../../DAO/RestaurantDAO';

export const type = 'setRestaurantBasicInfo';

const setRestaurantBasicInfo = ( { name, contactName, whatsappNumber, photo, UID }, callback ) => {
    return (dispatch, getState) => {
        var data = new FormData();
        data.append('UID', UID);
        data.append('basicInfo', JSON.stringify({ name: name, contactName: contactName, whatsappNumber: whatsappNumber }));
        if (photo)
          data.append('photo', photo);
        RestaurantDAO.saveBasicInfo(data, (menu) => {
            callback();
            dispatch( { type: type, payload: { name, whatsappNumber, UID } } )
        });
        
    }
}

export default setRestaurantBasicInfo;