import Cropper from 'react-easy-crop'
import React from "react";

class FileUploadZoom extends React.Component {
  state = {
    //image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/RedDot_Burger.jpg/1200px-RedDot_Burger.jpg',
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 4 / 3,
  };

  constructor(props) {
    super(props);
    if(this.props.sourcePhotoURL !== '') {
      var request = new XMLHttpRequest();
      request.open('GET', this.props.sourcePhotoURL, true);
      request.responseType = 'blob';
      const self = this;
      request.onload = function() {
        var reader = new FileReader();
          reader.readAsDataURL(request.response);
          reader.onload =  function(e){
            self.setState({ image: e.target.result });
          };
      };
      request.send();
    }
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ image: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onCropChange = crop => {
    this.setState({ crop })
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
    this.makeClientCrop(croppedAreaPixels);
  };

  async makeClientCrop(crop) {
    const image = document.getElementsByClassName('reactEasyCrop_Image')[0];
    if (crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        image,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = 1; //image.naturalWidth / image.width;
    const scaleY = 1; //image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
        this.props.onSelectedImage(blob);
      }, "image/jpeg");
    });
  }

  onZoomChange = zoom => {
    console.log(zoom, 'zoom');
    this.setState({ zoom })
  };

  render() {
    return (
      <div style={this.state.image ? { height: 240 } : {}}>
        <div>
          <input type="file" accept="image/*" onChange={this.onSelectFile} />
        </div>
        {this.state.image && (
          <Cropper
            image={this.state.image}
            crop={this.state.crop}
            zoom={this.state.zoom}
            aspect={this.state.aspect}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
          />
        )}
      </div>
    );
  }
}

export default FileUploadZoom;