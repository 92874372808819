import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { ValidatedTextField } from '../../../controls/ValidatedTextField';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
} from '@material-ui/core';
import { addCategory, changeNameCategory } from '../../../redux/actions/menu/menuActions';
import { connect } from 'react-redux';

class CategoryProductDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.currentCategory ? this.props.currentCategory.name : '',
      nameValid: false,
    };
  }
  /** Events **/
  onChange = () => {
    this.props.onCloseDialog();
    if( this.props.modeEdition ) {
      this.props.changeNameCategory({ UID: sessionStorage.getItem('UID'), categoryID: this.props.currentCategory.id, name: this.state.name });
    }
    else {
      this.props.addCategory({ categoryName: this.state.name, UID: sessionStorage.getItem('UID') });
    }
  }
  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onCloseDialog} aria-labelledby="modal-item-standard" aria-describedby="modal-item-standard" className="backend-dialog">
        <DialogTitle id="alert-dialog-slide-title" style={{padding: '10'}}>{this.props.modeEdition ? 'Editar Categoría' : 'Crear Categoría' }</DialogTitle>
        <DialogContent>
          <ListItem>
            <ValidatedTextField label="nombre" defaultValue={this.props.modeEdition ? this.state.name: ''} validations={[ {type: 'isRequired', msg: 'El nombre es requerido'} ]} onChange={(newValue) => { this.setState({ name: newValue }); }} onValidate={(isValid) => {this.setState({ nameValid: isValid}); }}></ValidatedTextField>
          </ListItem>
        </DialogContent>
        <DialogActions>
          <div className="container-space-between">
            <Button color="secondary" variant="contained" size="small" onClick={this.props.onCloseDialog}> Cancelar </Button>
            <Button color="primary" variant="contained" size="small" disabled={!this.state.nameValid} onClick={this.onChange}> <AddIcon /> {this.props.modeEdition ? 'Guardar' : 'Agregar' } </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  myMenu: state.myMenu,
});

const mapDispatchToProps = {
  addCategory,
  changeNameCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProductDialog);