import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { ValidatedTextField } from '../../../controls/ValidatedTextField';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  MenuItem,
} from '@material-ui/core';
import { addStandardProduct, updateStandardProduct, clearMenu } from '../../../redux/actions/menu/menuActions';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import FileUploadZoom from '../../../controls/FileUploadZoom';
import ImageUploader from 'react-images-upload';


class StandardProductButton extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.modeEdition) {
      this.state = {
        name: this.props.product.name,
        price: this.props.product.price,
        description: this.props.product.description,
        photoURL: this.props.product.photoURL,

        nameValid: false,
        priceValid: false,

        mainPhoto: null,
        photos: [],
        photoUrls: this.props.product.photos || [],

        isDialogOpen: false,

        loading: false,
      };
    }
    else {
      this.state = {
        name: '',
        price: '',
        description: '',

        nameValid: false,
        priceValid: false,

        mainPhoto: null,
        photos: [],
        photoUrls: [],

        isDialogOpen: false,

        loading: false,
      };
    }
  }
  resetValues = () => { this.setState({ name: '', price: '', description: '', photoURL: '', mainPhoto: null, photos: [] }) }
  /** Events **/
  onOpenDialog  = () => { 
    if(!this.props.modeEdition)
      this.resetValues();
    this.setState({ isDialogOpen: true });
    this.props.onClick();
  }
  onCloseDialog = () => { this.setState({ isDialogOpen: false }); }
  onModifyProduct = () => {
    this.setState({ loading: true });
    if(this.props.modeEdition) {
      this.props.updateStandardProduct({
        categoryID: this.props.category.id,
        productID: this.props.product.id,
        product: { name: this.state.name, price: this.state.price, description: this.state.description },
        UID: sessionStorage.getItem('UID'),
        mainPhoto: this.state.mainPhoto,
        photos: this.state.photos.length > 0 ? this.state.photos : null,
      },
      (res) => {
        this.props.enqueueSnackbar('guardado exitosamente', { variant: 'success' });
        this.props.clearMenu();
        this.setState({ loading: false });
        this.onCloseDialog();
      });
    }
    else
      this.props.addStandardProduct({
        UID: sessionStorage.getItem('UID'),
        categoryID: this.props.category.id,
        product: { name: this.state.name, price: this.state.price, description: this.state.description },
        mainPhoto: this.state.mainPhoto,
        photos: this.state.photos.length > 0 ? this.state.photos : null
      },
      (res) => {
        this.props.enqueueSnackbar('guardado exitosamente', { variant: 'success' });
        this.props.clearMenu();
        this.setState({ loading: false });
        this.onCloseDialog();
      });
  }
  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      photos: this.state.photos.concat(pictureFiles),
      photoUrls: this.state.photoUrls.concat(pictureDataURLs)
    }, this.checkIsValid);
  }
  render() {
    return (
      <>
        <MenuItem className={"truncated-text"} key="new-item-standard" onClick={this.onOpenDialog}>{this.props.buttonName || 'Producto estándar'}</MenuItem>
        <Dialog open={this.state.isDialogOpen} onClose={this.onCloseDialog} aria-labelledby="modal-item-standard" aria-describedby="modal-item-standard">
          <DialogTitle id="alert-dialog-slide-title">{this.props.modeEdition ? 'Editar Producto' : 'Agregar Producto'}</DialogTitle>
          <DialogContent>
            <ValidatedTextField label="nombre del producto" defaultValue={this.state.name} validations={[ {type: 'isRequired', msg: 'El nombre es requerido'} ]} onChange={(newValue) => { this.setState({ name: newValue }); }} onValidate={(isValid) => {this.setState({ nameValid: isValid}); }}></ValidatedTextField>
            <ValidatedTextField label="precio" defaultValue={this.state.price} validations={[ {type: 'isRequired', msg: 'El precio es requerido'}, {type: 'money', msg: 'Ingrese un precio válido'} ]} onChange={(newValue) => { this.setState({ price: newValue }); }} onValidate={(isValid) => {this.setState({ priceValid: isValid}); }}></ValidatedTextField>
            <TextField label="Descripción" defaultValue={this.state.description} fullWidth={true} multiline rowsMax={4} onChange={(e) => { this.setState({ description: e.target.value }); }}/>
            <div style={{position: 'relative'}}>
              <h4>Vista previa</h4>
              <FileUploadZoom sourcePhotoURL={this.state.photoURL} onSelectedImage={(blob) => {
                this.setState({
                  mainPhoto: blob
                });
              }}/>
            </div>
            <div style={{position: 'relative'}}>
              <h4>Fotos detalladas</h4>
              <List>
                {this.state.photoUrls.map(photo => {
                  return (
                    <>
                      <div className="image-list-item">
                        {/*
                        <IconButton className="btn-remove-image" aria-label="delete-image" onClick={() => {
                          var photos = this.state.photos;
                          var photoUrls = this.state.photoUrls;
                          var index = photoUrls.indexOf(photo);
                          photos.splice(index, 1);
                          photoUrls.splice(index, 1);
                          this.setState({
                            photos: photos,
                            photoUrls: photoUrls
                          });
                        }}>
                          X
                        </IconButton>
                        */}
                        <img alt={this.state.name} src={photo} />
                      </div>
                    </>
                  );
                })}
              </List>
              <div style={{ clear: 'both' }}></div>
              <ImageUploader
                  withIcon={true}
                  buttonText='Selecciona una imagen'
                  onChange={this.onDrop.bind(this)}
                  imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
                  maxFileSize={5242880}
                  withPreview={false}
                  accept="image"
                  withLabel={false}
                  fileSizeError='imagen muy grande (Máximo de 5 MB)'
                  fileTypeError='debes subir solo imágenes en formato .jpg, jpeg, .gif, .png, .gif'
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="container-right">
              <Button color="primary" variant="contained" size="small" disabled={!this.state.nameValid || !this.state.priceValid} onClick={this.onModifyProduct}>
                {this.state.loading && <CircularProgress size={24} />}
                <AddIcon /> {this.props.modeEdition ? 'Editar': 'Agregar'} 
              </Button>
              <Button color="secondary" variant="contained" size="small" onClick={this.onCloseDialog}> Cancelar </Button>
            </div>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = state => ( {
  myMenu: state.myMenu,
} );

const mapDispatchToProps = {
  addStandardProduct,
  updateStandardProduct,
  clearMenu,
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(StandardProductButton));