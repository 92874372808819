import RestaurantDAO from '../../../DAO/RestaurantDAO';

export const type = 'setRestaurantBasicAndLocationInfo';

export const errorType = 'ERROR';

const setRestaurantBasicAndLocationInfo = ( { name, contactName, whatsappNumber, UID, photo, coverageZones }, resolve, reject ) => {
    return (dispatch, getState) => {
        var data = new FormData();
        data.append('UID', UID);
        data.append('basicInfo', JSON.stringify({ name: name, contactName: contactName, whatsappNumber: whatsappNumber }));
        data.append('locationInfo', JSON.stringify({ coverageZones }));
        if (photo)
          data.append('photo', photo);

        RestaurantDAO.saveBasicAndLocationInfo(data).then(res => {
            resolve( { type: type, payload: { name, contactName, whatsappNumber, UID } } );
        })
        .catch(res => {
            reject(res);
        });
    }
}

export default setRestaurantBasicAndLocationInfo;