import RestaurantDAO from '../../../DAO/RestaurantDAO';
export const type = 'GET_RESTAURANT';

const getRestaurant = (UID, callback) => {
    return (dispatch, getState) => {
        RestaurantDAO.getRestaurant(sessionStorage.getItem('UID'), (data) => {
            if(data.results.length > 0) {
                dispatch( { type: type, payload: data.results[0] } )
            }
            callback();
        });
    }
};

export default getRestaurant;