import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  Typography,
} from '@material-ui/core';
import { AddProductButton } from '../AddProductButton';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { VisibleButton } from '../../../controls/VisibleButton';
import { changeVisibleCategory, deleteCategory, changeVisibleProduct, deleteProduct } from '../../../redux/actions/menu/menuActions';
import { connect } from 'react-redux';
import { ConfirmationDialog } from '../../../controls/ConfirmationDialog';
import CategoryProductDialog from './CategoryProductDialog';
import Product from '../../../controls/Product';

class ProductCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirmation: false,
      messageConfirmation: '',
      callbackConfirmation: () => {},
      openCategoryDialog: false,
    };
  }
  /** Events **/
  handleCloseEditItem = () => {}
  render() {
    let { category } = this.props;
    const categoryVisible = category ? category.visible : false;
    return (
      <>
        {category &&
          <Card className="container" >
            <CardHeader className={`title-category ${category.visible ? '': 'category-disabled' }`} title={category.name} onClick={() => { this.setState({ openCategoryDialog: true }); }} />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="div">
                <List>
                  {category.products && category.products.map((prod, index) => {
                    return (
                      <>
                        <Product product={prod} category={category} handleCloseEditItem={this.handleCloseEditItem} onDeleteProduct={() => {
                          this.setState({openConfirmation: true, messageConfirmation: '¿Estás seguro que deseas eliminar este producto?', callbackConfirmation: () => {
                            this.props.deleteProduct({ UID: sessionStorage.getItem('UID'), categoryID: category.id, productID: prod.id })
                          } });
                        }} />
                      </>
                    );
                  })}
                </List>
              </Typography>
            </CardContent>
            <CardActions disableSpacing className={`container-space-around ${category.visible ? '': 'category-disabled' }`}>
              <IconButton aria-label="delete-category" onClick={() => { this.setState({ openConfirmation: true, messageConfirmation: '¿Estás seguro que deseas eliminar esta categoría?', callbackConfirmation: () => { this.props.deleteCategory({ UID: sessionStorage.getItem('UID'), categoryID: category.id })} }); } }> <DeleteIcon fontSize="large" /> </IconButton>
              <VisibleButton visible={ categoryVisible } onChange={ visible => { this.props.changeVisibleCategory({ UID: sessionStorage.getItem('UID'), categoryID: category.id, visible }); } } />
              <AddProductButton category={category} />
            </CardActions>
          </Card>
        }
        <ConfirmationDialog 
          open={this.state.openConfirmation} 
          handleClose = {() => { this.setState({ openConfirmation: false }); }}
          message={ this.state.messageConfirmation } 
          callback={ this.state.callbackConfirmation }
        />

        <CategoryProductDialog open={this.state.openCategoryDialog} modeEdition={true} currentCategory={ category } onCloseDialog = { () => { this.setState({ openCategoryDialog: false }); } } />
      </>
    ); 
  }
}

const mapStateToProps = state => ( {} );

const mapDispatchToProps = {
  deleteCategory,
  changeVisibleCategory,
  changeVisibleProduct,
  deleteProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategory);