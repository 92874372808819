import React from 'react';
import {
  Button,
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ConfirmationDialog = (props) => {
  return (
    <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.handleClose} aria-labelledby="alert-dialog-delete-category" aria-describedby="alert-dialog-delete-category">
      <DialogTitle id="alert-dialog-slide-title">Confirmación</DialogTitle>
      <DialogContent> <DialogContentText id="alert-dialog-slide-description"> { props.message } </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary"> No </Button>
        <Button onClick={() => { props.handleClose(); props.callback(); }} color="primary"> Sí </Button>
      </DialogActions>
    </Dialog>
  )
};