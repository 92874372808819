import { setMenuType } from '../actions/menu/menuActions';

const defaultState = {};

/****************************************************************************************/
/******** Methods to parse the data from firebase into the client ********/
/****************************************************************************************/
/**
const convertProductToMetaProduct = (key, firebaseProduct) => {
  const name = Object.keys(firebaseProduct)[0];
  const product = firebaseProduct[name];
  const metaProduct = {
    key: key,
    name: name,
    product: product
  };
  return metaProduct;
}

const convertCategoryToMetaCategory = (key, firebaseCategory) => {
  const name = Object.keys(firebaseCategory)[0];
  const category = firebaseCategory[name];
  if (category.products) {
    const prodKeys = Object.keys(category.products);
    category.products = prodKeys.map(key => convertProductToMetaProduct(key, category.products[key]));
  }
  else
    category.products = [];
  const metaCategory = {
    key: key,
    name: name,
    category: category
  };
  return metaCategory;
}

const convertMenuToMetaMenu = (menu) => {
  if(menu.categories) {
    const catKeys = Object.keys(menu.categories);
    menu.categories = catKeys.map(key => convertCategoryToMetaCategory(key, menu.categories[key]));
  }
  const metaMenu = {
    menu: menu
  };
  return metaMenu;
}
**/
/****************************************************************************************/
/******** Reducer ********/
/****************************************************************************************/
function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case setMenuType: {
      return payload.menu;//convertMenuToMetaMenu(payload.menu);
    }
    default:
      return state;
  }
}

export default reducer;