import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { ValidatedTextField } from '../../../../controls/ValidatedTextField';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import addIngredient from '../../../../redux/actions/customProduct/addIngredient';
import { connect } from 'react-redux';

class IngredientCreatorButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      price: '',
      description: '',

      nameValid: false,
      priceValid: false,

      isDialogOpen: false
    };
  }
  /** Events **/
  onOpenDialog  = () => { this.setState({ isDialogOpen: true }); }
  onCloseDialog = () => { this.setState({ isDialogOpen: false }); }
  onCreateNewIngredient = () => {
    this.onCloseDialog();
    this.props.addIngredient({ category: this.props.category, ingredient: { name: this.state.name, visible: false } });
  }
  render() {
    return (
      <>
        <Button color="primary" variant="contained" size="small" onClick={ this.onOpenDialog }> Agregar Ingrediente </Button>

        <Dialog open={this.state.isDialogOpen} onClose={this.onCloseDialog} aria-labelledby="modal-item-standard" aria-describedby="modal-item-standard">
          <DialogTitle id="alert-dialog-slide-title">Agregar Ingrediente</DialogTitle>
          <DialogContent>
            <ValidatedTextField label="nombre del producto" defaultValue={this.state.name} validations={[ {type: 'isRequired', msg: 'El nombre es requerido'} ]} onChange={(newValue) => { this.setState({ name: newValue }); }} onValidate={(isValid) => {this.setState({ nameValid: isValid}); }}></ValidatedTextField>
          </DialogContent>
          <DialogActions>
            <div className="container-right">
              <Button color="primary" variant="contained" size="small" disabled={!this.state.nameValid} onClick={this.onCreateNewIngredient}> <AddIcon /> Agregar </Button>
              <Button color="secondary" variant="contained" size="small" onClick={this.onCloseDialog}> Cancelar </Button>
            </div>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = state => ( {} );

const mapDispatchToProps = {
  addIngredient,
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientCreatorButton);