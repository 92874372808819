import RestaurantDAO from '../../../DAO/RestaurantDAO';

export const setRestaurantPaymentInfoType = 'setRestaurantPaymentInfo';
export const changeVisiblePaymentMethodType = 'changeVisiblePaymentMethod';
export const deletePaymentMethodType = 'deletePaymentMethodType';

export const setRestaurantPaymentInfo = ( { UID, paymentMethod, QrCodeImage }, callback = () => {} ) => {
    return (dispatch, getState) => {
        var data = new FormData();
        data.append('UID', UID);

        const keys = Object.keys(paymentMethod);
        for(const index in keys){
            const key = keys[index];
            data.append(key, paymentMethod[key]);
        }

        RestaurantDAO.savePaymentInfo(data, (menu) => {
            callback();
            dispatch( { type: setRestaurantPaymentInfoType, payload: { paymentMethod } } )
        });
    }
}

export const changeVisiblePaymentMethod = ({ UID, index, visible }) => {
  return (dispatch, getState) => {
    const payload = { UID, index, visible };
    RestaurantDAO.changeVisiblePaymentMethod(payload, (menu) => {
      dispatch( { type: changeVisiblePaymentMethodType } );
    });
  }
}

export const deletePaymentMethod = ({ UID, index }) => {
  return (dispatch, getState) => {
    const payload = { UID, index };
    RestaurantDAO.deletePaymentMethod(payload, (menu) => {
      dispatch( { type: deletePaymentMethodType } );
    });
  }
}