import React from 'react';
import RestaurantBasicInfo from '../restaurant/basicInfo/RestaurantBasicInfo';
import RestaurantLocationInfo from '../restaurant/locationInfo/RestaurantLocationInfo';
import RestaurantPaymentInfo from '../restaurant/paymentInfo/RestaurantPaymentInfo';
import getRestaurant from '../../redux/actions/restaurantInitialSteps/getRestaurant';
import setRestaurantBasicAndLocationInfo from '../../redux/actions/myAccount/setRestaurantBasicAndLocationInfo';
import { connect } from 'react-redux';
import { Paper, Button, List, ListItem } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';

class EditMyAccountScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurantQueried: false,

      name: null, 
      whatsappNumber: null,
      photo: null,
      basicInfoValid: false,

      coverageZones: null,
      locationInfoValid: false,

      loadingAction: false,
    };
    this.getRestaurantInfo();
  }
  getRestaurantInfo() {
    this.props.getRestaurant(sessionStorage.getItem('UID'), () => { this.setState({ restaurantQueried: true }) });
  }
  render() {
    return (
      <>
      {this.state.restaurantQueried === false &&
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <CircularProgress size={24} />
        </div>
      }
      {this.state.restaurantQueried && 
        <div className="container">
          <Paper>
            <RestaurantBasicInfo onChange = {(name, contactName, whatsappNumber, photo) => {
              this.setState({ name: name, contactName: contactName, whatsappNumber: whatsappNumber, photo: photo });
            }} onChangeIsValid = { (isValid) => { this.setState({ basicInfoValid: isValid }) }} />
            <RestaurantLocationInfo onChange = {(coverageZones) => {
              this.setState({ coverageZones });
            }} onChangeIsValid = { (isValid) => { this.setState({ locationInfoValid: isValid }) }} />
            <RestaurantPaymentInfo />
          <List>
            <ListItem className="container-right">
              <Button color="primary" variant="contained" size="small" disabled={this.state.loadingAction} onClick={() => {
                this.setState({ loadingAction: true });
                this.props.setRestaurantBasicAndLocationInfo({
                  name: this.state.name,
                  contactName: this.state.contactName,
                  whatsappNumber: this.state.whatsappNumber,
                  UID: sessionStorage.getItem('UID'),
                  photo: this.state.photo,
                  coverageZones: this.state.coverageZones },
                (res) => {
                  this.props.enqueueSnackbar('guardado exitosamente', { variant: 'success' });
                  this.setState({ loadingAction: false });
                },
                (res) => {
                  var message = 'Error de conexión';
                  if(res)
                    if(res.content)
                      if(res.content.message)
                        message = res.content.message;
                  this.props.enqueueSnackbar(message, { variant: 'error' });
                });
               }}> {this.state.loadingAction && <CircularProgress size={24} />} Guardar </Button>
            </ListItem>
          </List>
        </Paper>
      </div>
      }
      </>
    );
  }
}

const mapStateToProps = state => ({
  
});

const mapDispatchToProps = {
  getRestaurant,
  setRestaurantBasicAndLocationInfo
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(EditMyAccountScreen));