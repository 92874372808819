export const DASHBOARD             = '/';
export const EDIT_MY_ACCOUNT       = '/edit-my-account';
export const EDIT_MENU             = '/edit-my-menu';
export const EDIT_CUSTOM_PRODUCT   = '/edit-custom-product';
export const FAQ                   = '/frequent-questions';
export const CONTACT_PAGE          = '/contact-page';
export const BACK_ESTABLECIMIENTOS = 'http://establecimientos.alacuenta.com/';

export const Routes = {
  getDashboard:     () => DASHBOARD,
  getEditMyAccount: () => EDIT_MY_ACCOUNT,
  getEditMenu:      () => EDIT_MENU,
  getCustomProduct: () => EDIT_CUSTOM_PRODUCT,
  getFAQ:           () => FAQ,
  getContactPage:   () => CONTACT_PAGE,
};